<template>
  <!-- <ion-card
      class="no-background ion-no-margin ion-margin-top"
      style="border: 1px solid #e2e2e2; width: 100%"
    > -->

  <div class="w-full flex justify-content-center py-4">
    <div v-if="loading">Caricamento in corso...</div>
    <ion-datetime
      v-else
      presentation="date"
      @ionChange="dateChanged"
      :highlighted-dates="highlightedDates"
      :preferWheel="false"
    ></ion-datetime>
  </div>

  <div class="my-4 section-title">ATTIVI</div>
  <!-- </ion-card> -->

  <div
    v-if="loading"
    style="height: 80px"
    class="flex flex-column justify-content-center align-items-center"
  >
    <span>Caricamento in corso...</span>
    <ion-skeleton-text :animated="true" style="width: 88%"></ion-skeleton-text>
  </div>
  <div v-else>
    <div v-if="itemsFiltered.length > 0" class="lista-contenitore">
      <transition-group
        name="block-slide-in"
        :style="{ '--i': itemsFiltered.length }"
      >
        <template v-for="(item, i) in itemsFiltered">
          <div
            :key="i"
            :style="{ '--i': i }"
            v-if="showList1 && showList2"
            class="ion-no-padding ion-margin-top"
            style="overflow: visible"
          >
            <CardCollapsable class="ion-margin-bottom">
              <template #title>
                <span class="font-bold">{{ item.titolo }}</span>
              </template>
              <template #body>
                <div class="flex flex-column">
                  <span class="text-12 mb-4 text-color-light">{{
                    item.annotazioni
                  }}</span>
                  <span class="text-color-light"
                    ><span class="font-bold">Dossier: </span
                    >{{ item.Dossier }}</span
                  >
                  <span class="text-color-light"
                    ><span class="font-bold">Cliente: </span
                    >{{ item.Cliente }}</span
                  >
                </div>
              </template>
              <template #footer>
                <div class="flex justify-content-between w-full mt-4">
                  <button @click="goto(item)" class="text-button">ENTRA</button>
                  <button class="text-button">
                    <a
                      target="_blank"
                      :href="item.luogo"
                      class="text-button flex align-items-center"
                    >
                      MAPPA
                      <ion-icon
                        class="icon-rounded bg-secondary ml-2"
                        :icon="pin"
                      ></ion-icon>
                    </a>
                  </button>
                </div>
              </template>
            </CardCollapsable></div
        ></template>
      </transition-group>
    </div>
    <div v-else>
      <ion-row class="ion-justify-content-center ion-align-items-center">
        <ion-col size="auto">
          <span style="color: gray"
            >Non sono presenti ordini per la data selezionata</span
          >
        </ion-col>
      </ion-row>
    </div>
  </div>
  <div class="my-4 section-title">CHIUSI</div>

  <div
    v-if="loading"
    style="height: 80px"
    class="flex flex-column justify-content-center align-items-center"
  >
    <span>Caricamento in corso...</span>
    <ion-skeleton-text :animated="true" style="width: 88%"></ion-skeleton-text>
  </div>
  <div v-else>
    <div v-if="pastItemsFiltered.length > 0" class="lista-contenitore">
      <transition-group
        name="block-slide-in"
        :style="{ '--i': pastItemsFiltered.length }"
      >
        <template v-for="(item, i) in pastItemsFiltered">
          <div :key="i" :style="{ '--i': i }" v-if="showList1 && showList2">
            <CardCollapsable class="ion-margin-bottom">
              <template #title>
                <span class="font-bold">{{ item.titolo }}</span>
              </template>
              <template #body>
                <div class="flex flex-column">
                  <span class="text-12 mb-4 text-color-light">{{
                    item.annotazioni
                  }}</span>
                  <span class="text-color-light"
                    ><span class="font-bold">Dossier: </span
                    >{{ item.Dossier }}</span
                  >
                  <span class="text-color-light"
                    ><span class="font-bold">Cliente: </span
                    >{{ item.Cliente }}</span
                  >
                </div>
              </template>
              <template #footer>
                <div class="flex justify-content-between w-full">
                  <button @click="goto(item)" class="text-button">ENTRA</button>
                  <button class="text-button">
                    <a
                      target="_blank"
                      :href="item.luogo"
                      class="text-button flex align-items-center"
                    >
                      MAPPA
                      <ion-icon
                        class="icon-rounded bg-secondary ml-2"
                        :icon="pin"
                      ></ion-icon>
                    </a>
                  </button>
                </div>
              </template>
            </CardCollapsable></div
        ></template>
      </transition-group>
    </div>
    <div v-else>
      <ion-row class="ion-justify-content-center ion-align-items-center">
        <ion-col size="auto">
          <span style="color: gray"
            >Non sono presenti ordini per la data selezionata</span
          >
        </ion-col>
      </ion-row>
    </div>
  </div>
</template>

<script>
import {
  IonAvatar,
  IonLabel,
  IonSelectOption,
  IonRow,
  IonCol,
  IonCard,
  IonList,
  IonItem,
  IonInput,
  IonSelect,
  IonTextarea,
  IonIcon,
  IonButton,
  IonFab,
  IonFabButton,
  modalController,
  //   IonRouterOutlet,
  loadingController,
  IonLoading,
  IonDatetime,
  IonSkeletonText,
} from "@ionic/vue"
import { pin } from "ionicons/icons"
import ApiService from "../../common/service.api"
import {
  warningOutline,
  add,
  chatboxOutline,
  chatbox,
  checkmarkCircleOutline,
  alertCircleOutline,
} from "ionicons/icons"
// import { defineComponent } from "vue";
import AxertaChat from "../Modals/AxertaChat.vue"
import AxertaNewLog from "../Modals/AxertaNewLog.vue"
import MasterLayout from "../../components/MasterLayout"
import { mapGetters } from "vuex"
import CardCollapsable from "../../components/Nuovi/CardCollapsable.vue"

export default {
  name: "Lista Ordini",
  components: {
    IonAvatar,
    IonLabel,
    IonSelectOption,
    IonRow,
    IonCol,
    IonCard,
    IonList,
    IonItem,
    IonInput,
    IonSelect,
    IonTextarea,
    IonIcon,
    IonButton,
    IonFab,
    IonFabButton,
    MasterLayout,
    IonLoading,
    CardCollapsable,
    IonDatetime,
    IonSkeletonText,
  },

  data() {
    return {
      showList1: false,
      showList2: false,
      loading: false,
      dateSelected: new Date(),
      pastItemsFiltered: [],
      itemsFiltered: [],
      pin,
      warningOutline,
      add,
      chatboxOutline,
      chatbox,
      checkmarkCircleOutline,
      alertCircleOutline,
      categorySelected: this.$route.query.categoriaSelezionata,
      totaleGenerale: 0,
      items: [],
      pastItems: [],
      options: [
        {
          text: "Tutte le categorie",
          value: 0,
          margine: 0,
        },
      ],
      highlightedDates: [],
    }
  },
  created() {
    this.showList1 = false
    this.showList2 = false
    // this.getOptions();
    // this.getItems();
    this.getData()
    window.loadingCPresented = null
  },
  mounted() {
    setTimeout(() => {
      this.$nextTick(() => {
        this.showList2 = true
      })
    }, 1)
  },

  methods: {
    populateHighlitedDates(array) {
      array.forEach((element) => {
        if (element.dataOra_inizio) {
          this.highlightedDates.push({
            date: element.dataOra_inizio.slice(0, 10),
            textColor: "#ffffff",
            backgroundColor: "#c5b06e",
          })
        }
      })
    },
    dateChanged(event) {
      this.dateSelected = new Date(event.detail.value)
    },
    getData() {
      this.items.splice(0)
      this.pastItems.splice(0)
      this.itemsFiltered.splice(0)
      this.loading = true
      ApiService.get("DossierManager/GetOrdiniDiServizio/0")
        .then((res) => {
          const today = new Date()
          res.data.forEach((ordine) => {
            ordine.collapsed = true
            let dataOrdine = new Date(ordine.dataOra_inizio)
            dataOrdine.setDate(dataOrdine.getDate() + 1)

            if (new Date(dataOrdine) < new Date(today)) {
              this.pastItems.push(ordine)
            } else {
              this.items.push(ordine)
            }
            setTimeout(() => {
              this.$nextTick(() => {
                this.showList1 = true
              })
            }, 1)
          })
          this.populateHighlitedDates(res.data)
        })
        .finally(() => {
          this.loading = false
        })
    },
    // async presentLoading() {
    //   console.log("presentLoading");
    //   window.loadingCPresenting = true;
    //   if (!window.loadingC) {
    //     window.loadingC = await loadingController.create({
    //       cssClass: "my-custom-loading",
    //       message: "Please wait...",
    //       // duration: this.timeout,
    //     });
    //   }
    //   if (!window.loadingCPresented) {
    //     await window.loadingC.present();
    //     window.loadingCPresented = true;
    //   }
    // },
    // async dismissLoading() {
    //   console.log("dismissLoading");
    //   if (window.loadingCPresented) {
    //     await window.loadingC.dismiss();
    //     window.loadingCPresenting = false;
    //     window.loadingCPresented = false;
    //     // // Sic
    //     // const elements = document.getElementsByClassName('my-custom-loading');
    //     // while(elements.length > 0){
    //     //   elements[0].parentNode.removeChild(elements[0]);
    //     // }
    //   } else if (window.loadingCPresenting) {
    //     setTimeout(() => {
    //       this.dismissLoading();
    //     }, 100);
    //   }
    // },
    nameFromId(Id) {
      return this.options.filter((element) => Id == element.id).nome
    },
    goto(item) {
      // this.$router.push('descriozione-ordine/' + id)
      this.$router.push("/axerta/descrizione-ordine/" + item.id)
    },
    // getItems() {
    //   console.log("GET ITEMS TREIGGERED");
    //   this.items.splice(0);
    //   this.totaleGenerale = 0;
    //   ApiService.get("SalesChat/GetOrders").then((res) => {
    //     res.data.forEach((element) => {

    //       if (element.id_status !== 4) {
    //         this.items.push(element);
    //         this.totaleGenerale = this.totaleGenerale + element.margineValue;
    //       }
    //     });
    //   });
    // },
    // getOptions() {
    //   this.options.splice(1);

    //   ApiService.get("SalesChat/GetProducts").then((res) => {
    //     res.data.forEach((element) => {
    //       element.text = element.nome;
    //       element.value = element.id;
    //       this.options.push(element);
    //     });
    //   });
    // },
    modalCloseHandler(item) {
      // this.items.forEach((element) => {
      //   if (element.id == item.ticketId) {
      //     element.status_text = "Closed";
      //   }
      // });
      // this.getOptions();
      // this.getItems();
    },
    async openModalChat(item) {
      const modal = await modalController.create({
        component: AxertaChat,
        cssClass: "common-modal",
        componentProps: {
          item: item,
        },
      })
      await modal.present()

      let modalResponse = await modal.onDidDismiss()
      console.log("MODAL RESPONSE", modalResponse)

      modalResponse && this.modalCloseHandler()
    },
    async openModalNewKpiChat() {
      let options = this.options
      //CALL OPTIONS FOR THE NEW CHAT

      // await ApiService.get("Option/HelpDeskType").then((res) => {
      //   res.data.forEach((element) => {
      //     options.push(element);
      //   });
      // });

      const modal = await modalController.create({
        component: AxertaNewLog,
        cssClass: "common-modal",
        componentProps: { options },
      })

      modal.onDidDismiss().then(async () => {
        // await ApiService.get(`SalesChat/GetOrders`).then((res) => {
        //   this.items = [];
        //   res.data.forEach((element) => {
        //     this.items.push(element);
        //   });
        // });
      })
      return modal.present()
    },
  },
  computed: {
    ...mapGetters("loading", {
      isLoading: "isLoading",
    }),
    categorySelectedText() {
      let string = ""
      this.options.forEach((element) => {
        if (element.value == this.categorySelected) {
          string = element.text
        }
      })
      return string
    },
    // computed_listItems() {
    //   let arr = [];
    //   if (this.categorySelected == null || this.categorySelected == "") {
    //     this.items.forEach((element) => {
    //       arr.push(element);
    //     });
    //   } else {
    //     this.items.forEach((element) => {
    //       if (element.product_id == this.categorySelected) {
    //         arr.push(element);
    //       }
    //     });
    //   }
    //   return arr;
    // },
    // totale() {
    //   let int = 0
    //   this.computed_listItems.forEach((element) => {
    //     int += element.margineValue
    //   })
    //   return int
    // },
    computed_debug() {
      if (this.$cookies.get("debug") == 1) {
        return false
      } else {
        return true
      }
    },
    filteredItems() {
      return this.items.filter(
        (ordine) =>
          new Date(ordine.dataOra_inizio) == new Date(this.dateSelected)
      )
    },
    filteredPastItems() {
      if (this.pastItems) {
        return this.pastItems.filter(
          (ordine) =>
            new Date(ordine.dataOra_inizio) == new Date(this.dateSelected)
        )
      }
      return []
    },
  },
  watch: {
    dateSelected: function (e) {
      this.showList1 = false
      this.showList2 = false
      this.pastItemsFiltered = []
      this.itemsFiltered = []
      this.pastItems.forEach((element) => {
        if (
          new Date(element.dataOra_inizio).toDateString() ==
          new Date(e).toDateString()
        ) {
          this.pastItemsFiltered.push(element)
          setTimeout(() => {
            this.$nextTick(() => {
              this.showList1 = true
            })
          }, 1)
        }
      })
      this.items.forEach((element) => {
        if (
          new Date(element.dataOra_inizio).toDateString() ==
          new Date(e).toDateString()
        ) {
          this.itemsFiltered.push(element)
          setTimeout(() => {
            this.$nextTick(() => {
              this.showList1 = true
            })
          }, 1)
        }
      })

      setTimeout(() => {
        this.$nextTick(() => {
          this.showList2 = true
        })
      }, 1)
    },
    // isLoading: function (e) {
    //   console.log("checkloading", e);
    //   if (e) {
    //     this.presentLoading();
    //   } else {
    //     this.dismissLoading();
    //   }
    // },
  },
}
</script>
